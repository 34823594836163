import * as React from 'react';
import { ConfigConsumer } from '../config-provider';
export default class BreadcrumbSeparator extends React.Component {
    constructor() {
        super(...arguments);
        this.renderSeparator = ({ getPrefixCls }) => {
            const { children } = this.props;
            const prefixCls = getPrefixCls('breadcrumb');
            return <span className={`${prefixCls}-separator`}>{children || '/'}</span>;
        };
    }
    render() {
        return <ConfigConsumer>{this.renderSeparator}</ConfigConsumer>;
    }
}
BreadcrumbSeparator.__ANT_BREADCRUMB_SEPARATOR = true;
