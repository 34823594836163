import { getNodeChildren, convertTreeToEntities } from 'rc-tree/lib/util';
var Record;
(function (Record) {
    Record[Record["None"] = 0] = "None";
    Record[Record["Start"] = 1] = "Start";
    Record[Record["End"] = 2] = "End";
})(Record || (Record = {}));
// TODO: Move this logic into `rc-tree`
function traverseNodesKey(rootChildren, callback) {
    const nodeList = getNodeChildren(rootChildren) || [];
    function processNode(node) {
        const { key, props: { children }, } = node;
        if (callback(key, node) !== false) {
            traverseNodesKey(children, callback);
        }
    }
    nodeList.forEach(processNode);
}
export function getFullKeyList(children) {
    const { keyEntities } = convertTreeToEntities(children);
    return Object.keys(keyEntities);
}
/** 计算选中范围，只考虑expanded情况以优化性能 */
export function calcRangeKeys(rootChildren, expandedKeys, startKey, endKey) {
    const keys = [];
    let record = Record.None;
    if (startKey && startKey === endKey) {
        return [startKey];
    }
    if (!startKey || !endKey) {
        return [];
    }
    function matchKey(key) {
        return key === startKey || key === endKey;
    }
    traverseNodesKey(rootChildren, (key) => {
        if (record === Record.End) {
            return false;
        }
        if (matchKey(key)) {
            // Match test
            keys.push(key);
            if (record === Record.None) {
                record = Record.Start;
            }
            else if (record === Record.Start) {
                record = Record.End;
                return false;
            }
        }
        else if (record === Record.Start) {
            // Append selection
            keys.push(key);
        }
        if (expandedKeys.indexOf(key) === -1) {
            return false;
        }
        return true;
    });
    return keys;
}
export function convertDirectoryKeysToNodes(rootChildren, keys) {
    const restKeys = [...keys];
    const nodes = [];
    traverseNodesKey(rootChildren, (key, node) => {
        const index = restKeys.indexOf(key);
        if (index !== -1) {
            nodes.push(node);
            restKeys.splice(index, 1);
        }
        return !!restKeys.length;
    });
    return nodes;
}
export function getFullKeyListByTreeData(treeData, keys = []) {
    (treeData || []).forEach(item => {
        if (item.children) {
            keys.push(item.key);
            keys.concat(getFullKeyListByTreeData(item.children, keys));
        }
        else {
            keys.push(item.key);
        }
    });
    return keys;
}
