export const actionNameSymbol = '__actionName__';
export const actionSymbol = '__action__';
export const listenSymbol = '__listen__';
export const metaSymbol = '__meta__';
export const reducerSymbol = '__reducer__';
export const selectDependenciesSymbol = '__selectDependencies__';
export const selectImpSymbol = '__selectImp__';
export const selectStateSymbol = '__selectState__';
export const selectSymbol = '__select__';
export const thunkSymbol = '__thunk__';
